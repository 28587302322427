@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@700&display=swap');
.profile_header {
    padding: 0px 50px;
    color: #8a2be2;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 600;
}

.profile_sec {
    display: flex;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    padding: 7px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.profile_section {
    display: flex;
    justify-content: center;
}

.profile_nav_white {
    color: white;
}

.sp_bio {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
}

.sp_bio_contant {
    color: black;
    text-align: center;
    width: 684px;
    margin-top: -300px;
    font-size: 24px;
    margin-bottom: 100px;
}

.sp_budget_id {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.sp_budget_add {
    border-style: dotted;
    border-radius: 17px;
    padding: 14px 37px;
    color: #b3b3b3;
    font-size: 57px;
    text-align: center;
    cursor: pointer;
    background: #1e1e1e;
}

.sp_budget_add_id {
    width: 100%;
    height: 100vh;
    color: white;
    position: fixed;
    top: 0;
    background: #000000ab;
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disnone {
    transition: 400ms;
    display: none;
}

.sp_add_img_here {
    display: flex;
    flex-direction: column;
    margin-top: -407px;
    align-items: center;

}

.sp_budger_close {
    font-size: 48px;
    color: black;
    font-weight: bolder;
    transform: rotate(11);
    transform: rotate(133deg);
    width: 28px;
    height: 28px;
    margin-top: 67px;
    cursor: pointer;
}

.sp_budger_close:hover {

    color: rgb(206, 12, 255);
    transition: 400ms;

}

.sp_edit_data_here {
    margin-top: 75px;
    margin-bottom: -108px;
    position: relative;
    font-size: 30px;

}

.sp_edit_data div {
    opacity: 0;
}

.sp_edit_data:hover div {
    opacity: 1;
    transition: 400ms;
}

.sp_edit_data:hover .sp_repeys {
    opacity: 0.2;
    transition: 400ms;
}

.sp_add_input {
    position: absolute;
    padding: 7px;
    background: transparent;
    margin-top: 42px;
    color: rgb(255, 255, 255);
    margin-left: 68px;
    font-size: 22px;
    border: none;
    text-align: center;
}

.sp_add_btn div {
    position: absolute;
    color: white;
    /* background: black; */
    font-size: 20px;
    font-weight: bolder;
}

.sp_add_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 40px;
}

.sp_userbio_contant {
    display: flex;
    flex-direction: column;
    margin-top: -350px;
    align-items: center;  
}

.sp_userbio_contant textarea {
    width: 780px;
    height: 217px;
    background: transparent;
    border: 1px solid white;
    border-radius: 8px;
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-family: 'Catamaran', sans-serif;
}

.sp_userbio_contant button {
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 13px;
    padding: 10px;
}

.exp_para_submit {
    text-align: center;
    margin-top: -41px;
    font-size: 20px;
    font-weight: 800;
    color: red;
    cursor: pointer;
}

.exp_input_para {
    margin-top: 21px;
    width: 342px;
    background: white;
    position: relative;
    height: 83px;
    margin-bottom: 29px;
    border-radius: 5px;
    border: none;
    font-size: 20px;
    color: black;
}

input::file-selector-button {
    font-weight: bold;
    color: dodgerblue;
    padding: 0.5em;
    border: thin solid grey;
    border-radius: 3px;
}

.ex_summary_cont {
    margin-top: 32px;
    font-size: 23px;
    background: #c9c9c9;
    padding: 8px;
    border-radius: 5px;
    width: 64%;
}

.exp_section_set {
    display: flex;
    margin-top: 328px;
    width: 1200px;
    flex-wrap: wrap;
    justify-content: center;
}

.pdf_para-set{
    margin-top: -80px;
    font-size: 20px;
    background: #c9c9c9;
    position: relative;
    height: 72px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: black;
    text-align: center;
}
.pdf_link_set{
    text-decoration: none;
    color: black;
    cursor: pointer;
    transition: 0.3s;
}
.pdf_link_set:hover{
    color: purple;
    transition: 0.3s;
    
}
.coming_soon_style{
    text-align: center;
}
.coming_soon_style img{
    width: 700px;
}
@media (max-width: 480px) {
  

.profile_header {
    padding: 0px 10px;
   
    font-size: 14px;
}
.sp_bio_contant {
    color: white;
    text-align: center;
    width: 90%;
    margin-top: -195px;
    font-size: 17px;
    margin-bottom: 59px;

}
.sp_bio img{
    opacity: 0;
    width: 100%;
}
.coming_soon_style img{
    width: 90%;
}
}