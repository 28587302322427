.header_section {
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    /* box-shadow: 0px 0px 1px 0px white; */
    transition: 500ms;
    z-index: 12;
    background-image: url('../../assets/image\ \(14\).png');
    background-size: 637px;
    height: 111px;
}

.header_scroll {
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    /* background: #371741; */
    /* background: #171717; */
    /* box-shadow: 0px 0px 6px 0px #b6b6b6; */
    /* box-shadow: 0px 0px 6px 0px #7f319d; */
    transition: 500ms;
    z-index: 12;
    background-image: url('../../assets/image\ \(14\).png');
    background-size: 637px;
    height: 111px;
}

.header_sec {
    display: flex;
    padding: 15px 0px;
    justify-content: space-between;
    width: 100%;

}

.head_link {
    display: flex;
    align-items: center;
    padding: 7px 25px;
}

.header_icon {

    width: 213px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.head_link a {
    color: #000000;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 22px;
    transition: 300ms;
    font-weight: bolder;
}

.head_link_active {
    display: flex;
    align-items: center;
    padding: 7px 25px;
}

.head_link_active a {
    color: #6e32a3;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 22px;
    transition: 300ms;
    font-weight: bolder;
}

.head_link a:hover {
    transition: 300ms;
    color: #6e32a3;
}

.header_btn {
    display: flex;
    align-items: center;
    width: 235px;
    justify-content: center;
}

.header_btn img {
    cursor: pointer;
}

.header_loing_line {
    display: none;
    transform-origin: 0% 100% 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    height: 1px;
    background: #ef0e3b;
    width: 0px;
    margin-left: -39px;
    animation: linewid 200ms;
    position: fixed;
    right: 0;
}
.create_header_btn{
    display: flex;
    justify-content: center;
    height: 47px;
    border: 1px solid blueviolet;
    border-radius: 3px;
    align-items: center;
}
.create_header_btn p{
    color: black;
    display: flex;
    align-items: center;
   
    font-size: 22px;
    padding: 5px 30px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bolder;
    /* border: 1px solid #a84df1; */
    border-radius: 3px;
    margin-right: -17px;
}
.create_header_btn p:hover{
    transition: .5s;
    color: #4b206d;
}
.bell_icon_number{
    display: flex;
    align-items: flex-start;
    padding: 20px;
    margin-left: -50px;
    margin-right: 50px;
    cursor: pointer;
}
.bell_icon_number div{
    background: red;
    padding: 3px;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
    margin-left: -13px;
}
.dis_width {
    width: 111px;
    transition: 300ms;
}

@keyframes linewid {
    0% {
        width: 0px;
    }

    100% {
        width: 111px;
    }
}

.loginsvg {
    width: 55px;
    
    transition: 500ms;
}

.loginsvgwid {
    width: 0px;
    height: 0px;
    transition: 500ms;
}

.header_cross_btn {
    position: fixed;
    top: 20px;
    right: 88px;
    cursor: pointer;
}

.header_cross_btn span {
    color: white;
    font-size: 40px;
}

.newline_side {
    transform: matrix(1, 0, 0, 1, 0, 0);
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 1px;
    background: #ef0e3b;
    z-index: 15;
    animation: newsideline 100ms 500ms;
    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}

@keyframes newsideline {
    0% {
        position: fixed;
        height: 0vh;
        width: 1px;
        top: 0;
        right: 0;
    }

    100% {
        position: fixed;
        height: 100vh;
        width: 1px;
        top: 0;
        right: 0;
    }
}



/* .header_btn button{
    padding: 9px 23px;
    background: #00000036;
    
    border-radius: 10px;
    color: white;
    border: none;
    font-weight: bold;
    cursor: pointer;
} */
.burger {
    display: none;
    position: fixed;
    top: 22px;
    right: 25px;
}


.header_link_cont {
    display: flex;
}


.loginpage {
    width: 100%;
    height: 100%;
    /* background: linear-gradient(89deg,#351944 -15%,#531f59 53%,#351944); */
    /* background: #171717; */
    background-image: url('../../assets/Bg\ 2.png');
    /* background: #bd38ff; */
    position: fixed;
    z-index: 212;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    top: 0;
    transition: 500ms;
    left: 100%;
}

.loginpagewidthfull {
    transition: 1s;
    width: 100%;
    left: 0%;
    height: 100%;
}

.dis_none {
    display: none;
    /* transition: 1s; */
}

.animated_login_btn {
    opacity: 0;
    color: #ea4d5a;
    padding: 0px 6px;
    transition: 300ms;
    width: 0px;
    height: 2px;
    transition: 500ms;
}

.btn_new {
    background: black;
    color: white;
    /* border: 1px solid; */
    border: none;
    padding: 10px 40px;
    border-radius: 24px;
    font-size: 22px;
    cursor: pointer;
    transition: 300ms;

}

.btn_new:hover {
    box-shadow: 0px 0px 4px 0px white;
    transition: 300ms;
}

.h_ac_sec {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-right: 50px; */
    color: white;
    cursor: pointer;
}


.h_ac_img img {
    width: 38px;
    border-radius: 20px;

}

.h_ac_pername {
    font-size: 21px;
    padding: 8px;
    color: black;
}

.h_ac_dorpdown {
    position: absolute;
    background: #000000;
    border-radius: 4px;
    margin-top: 93px;
    color: white;
    border: 1px solid #4b206d;
    right: 24px;
}

.h_ac_dorpdown div {
   text-transform: uppercase;
    margin: 19px;
    padding: 5px 8px;
    cursor: pointer;
    width: 165px;
    font-size: 16px;
    border-right: 3px solid #000000;
    text-align: right;
    transition: .5s;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 10px;
    
}

.h_ac_dorpdown div:hover {
    margin: 19px;
    padding: 5px 8px;
    cursor: pointer;
    width: 164px;
    font-size: 16px;
    border-right: 3px solid #4b206d;
    text-align: right;
    color: #4b206d;
    transition: .5s;
}

.h_ac_dorpdown div:hover {
    background-color: rgb(0, 0, 0);
    transition: 300ms;

}

.login_btn_style {
    position: absolute;
    text-align: center;
    margin-bottom: -31px;
    color: black;
    position: absolute;
    font-size: 19px;
}

.login_btn_style span:nth-child(1) {
    transform: translate(-5px, -6px) rotate(26deg);
    display: inline-block;
}
.login_btn_style span:nth-child(2) {
    transform: translate(-3px, -2px) rotate(15deg);
    display: inline-block;
}
.login_btn_style span:nth-child(3) {
    transform: translate(-1px, -2px) rotate(11deg);
    display: inline-block;
}
.login_btn_style span:nth-child(4) {
    transform: translate(0px, 0px) rotate(0deg);
    display: inline-block;
}
.login_btn_style span:nth-child(5) {
    transform: translate(1px, -1px) rotate(-13deg);
    display: inline-block;
}
.login_btn_style span:nth-child(6) {
    transform: translate(3px, -2px) rotate(-23deg);
    display: inline-block;
}
.login_btn_style span:nth-child(7) {
    transform: translate(5px, -6px) rotate(-23deg);
    display: inline-block;
}

.ls_redirct{
    width: 500px;
}
.register_redi_spons{
     
    text-decoration: none;
    color: black;
    margin-left: 85px;
}

.register_redi_gamer{
    text-decoration: none;
    color: black;
    margin-right: 85px;
}
.home_login_btn{
    display: flex;
    justify-content: center;
    margin-top: 80px;
}
.header_logo_set b{
    margin-left: 10px;
    text-transform: uppercase;
    margin-bottom: -7px;
}
.register_redi_spons div p{
    position: absolute;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bolder;
     margin-top: 15px;
}
.register_redi_gamer div p{
    position: absolute;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bolder;
     margin-top: 15px;
}
.login_with_me{
    position: absolute;
    color: black;
    font-size: 25px;
    margin-top: 86px;
    text-transform: uppercase;
    font-weight: bolder;
    margin-left: -16px;
}
@media (max-width: 1024px) {

    .ls_redirct {
        width: 350px;
    }

    .head_link_active a {
        font-size: 17px;
    }
    .head_link a {
        font-size: 18px;
    }
}




@media (max-width:900px) {
    .burger {
        display: block;
    }

    .header_sec {
        display: flex;
        flex-direction: column;
    }

    .header_link_cont {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        background: #eeeeee;
    }

    .displaynone {
        display: none;
        transition: 500ms;
    }

    /* .header_section {
        background: linear-gradient(89deg, #351944 -15%, #531f59 53%, #351944);
    } */



    .burgerfirstchild {
        width: 40px;
        transform: rotate(317deg);
        margin-top: 11px;
        transition: 500ms;
    }

    .burgersecondchild {
        display: none;
        transition: 500ms;
    }

    .burgerfourchild {
        display: none;
        transition: 500ms;
    }

    .burgerthirdchild {
        width: 40px;
        transform: rotate(46deg);
        margin-top: -7px;
        transition: 500ms;
    }

    .burger div {
        margin: 3px;
        height: 5px;
        width: 34px;
        background-color:  #62437c;
        border-radius: 3px;
    }



}

@media (max-width: 834px) {
    .register_redi_spons{
     
        margin-left: 0px;
    
    }
    .home_login_btn{
        
        margin-top: 25px;
    }
  

   
    .register_redi_gamer{
        margin-right: 0px;
    }
}

@media (max-width: 480px) {
   
    .login_with_me{
        position: absolute;
        color: black;
        font-size: 18px;
        margin-top: 60px;
        text-transform: uppercase;
        font-weight: bolder;
        margin-left: -16px;
    }
    .register_redi_spons div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .register_redi_spons div p{
        position: absolute;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bolder;
         margin: auto;
    }

    .register_redi_gamer div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .register_redi_gamer div p{
        position: absolute;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bolder;
         margin: auto;
    }



    .header_cross_btn {
        position: fixed;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    .header_scroll {
     
        background-size: cover;
        height: 83px;
    }
    .header_logo_set b{
        margin-left: 5px;
        text-transform: uppercase;
        margin-bottom: -5px;
        font-size: 12px;
    }
   
    .header_section {
        position: fixed;
        width: 100%;
        height: 83px;
        top: 0;
        display: flex;
        justify-content: center;
        transition: 500ms;
        z-index: 12;
        background-size: cover;
    }
    .header_icon {
        width: 155px;
    }
    .h_ac_sec{
        display: none;
    }
    .ls_redirct {
        width: 230px;
    }
    .header_btn {

        background: #ebebeb;
        width: 89%;
        padding-bottom: 25px;
        padding-left: 23px;
        justify-content: end;
        padding-right: 35px;
    }
    
    .header_link_cont {
        margin-top: 0px;
        padding-top: 20px;
        background: #ebebeb;
    }
    .head_link_active {
      
        justify-content: flex-end;
    }
    .head_link {
      
        justify-content: flex-end;
    }
}