.card_budget_set{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
}
.card_more_options{
    display: flex;
    justify-content: flex-end;
    width: 400px;
    transition: .5s;
}

.card_more_options span{
    font-size: 35px;
    cursor: pointer;
    opacity: 0;
    transition: .5s;
}
.card_budget_set:hover .card_more_details{
    opacity: 0.5;
    transition: .5s;
}

.card_budget_set:hover .card_more_options span{
    opacity: 1;
}
.card_more_options span:hover{
    color: purple;

}
.card_delete_confirm{
    padding: 20px;
    background: white;
    color: black;
    border-radius: 21px;
    text-align: center;
}
.confirm_delete_btn{
    padding: 6px;
    background: purple;
    border: none;
    border-radius: 4px;
    font-size: 20px;
    color: white;
    cursor: pointer;
    transition: .3s;
}
.confirm_delete_btn:hover{
    background-color: black;
    padding: 16px;
}
