.More_sectionss{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.more_faq_cont{
    width: 1000px;
    box-shadow: 0px 0px 5px 0px #4b206d;
    padding: 4px;
    border-radius: 5px;
    margin: 10px;
    transition: 0.5s;
}
.more_faq_cont:hover h3{
    transition: 0.5s;
    color: #642c91;
}
.more_faq_cont:hover span{
    transition: 0.5s;
    color: #642c91;
}
.more_faq_cont p{
  
    padding-top: 0px;
    margin-top: 1px;

    color: #642c91;
}
@media (max-width: 1024px) {


    .more_faq_cont {
        width: 90%;
    }
}