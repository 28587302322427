.expect_card_pdf{
    margin-bottom: -50px;
    position: relative;
    text-align: end;
    opacity: 0;
    transform: .5s;
}
.expect_card_pdf span{
    font-size: 35px;
    color: black;
    cursor: pointer;
    transition: .5s;
}
.expect_card_pdf span:hover{
    color: purple;
}
.pdf_link_set:hover .expect_card_pdf{
    opacity: 1;
    transform: .5s;
}