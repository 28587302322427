.about_line {
    width: 40px;
    height: 4px;
    background: #7b259b;
    border-radius: 5px;
    margin: 10px 30px;
    cursor: pointer;

}

.ab_sect_info {
    text-align: center;
    color: white;
    width: 900px;
    height: 400px;
    margin-left: 20px;
    border: 4px solid rgb(69, 34, 82);
    box-shadow: rgb(69, 34, 82) 0px 0px 15px 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 41px 18px;
}

.ab_sect_line {
    transition: 400ms;
    box-shadow: 0px 0px 13px 4px #7b259b;
}

.social_home_page img {
    margin: 10px;
    width: 150px;
    cursor: pointer;
}

.home_number_set {
    margin: 10px;
    width: 80px;
    height: 80px;
    background: linear-gradient(0deg, black, blueviolet);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 46px;
    font-family: auto;
    transform: rotateX(360deg);
}

.home_member_set_cont {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
}

.member_name_contant {
    color: white;
    text-align: center;
    margin-top: -150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.member_name_contant b {
    font-size: 20px;
}

.home_re_sp {
    font-size: 35px;
    text-transform: uppercase;
    font-weight: bolder;
}
.home_down_app{
    width: 350px;
}
.member_img_set{
    width:400px;
}
.about_img_width{
    width: 300px;
}
.about_contant_here{
    font-size: 18px;
    padding: 5px 30px;
    margin: 0px;
}
.social_home_page{
    text-align: center;
    margin-bottom: 75px;
}
.footer_link_cortoon{
    display: flex;
    justify-content: center;
}
.partner_hall{
    display: flex;
    justify-content: center;
}
.home_video{
    width: 100%;
}
.about_img_here{
    width: 500px;
}
.surya_style{
    width: 250px;
}


@media only screen and (max-width: 1720px) {
    .social_home_page img {
        width: 125px;
    }
    .member_img_set {
        width: 350px;
    }
    .about_img_width {
        width: 250px;
    }
    .ab_sect_info {
       
        width: 839px;
        height: 395px;
        
        padding: 30px 18px;
    }

}
@media only screen and (max-width: 1570px) {
    .social_home_page img {
       
        width: 100px;
        
    }
    .home_down_app {
        width: 300px;
    }
    .member_img_set {
        width: 310px;
    }
    .member_name_contant {
       
        margin-top: -128px;
    }
    .member_name_contant b {
        font-size: 16px;
    }
    .about_contant_here{
        font-size: 17px;
      
    }

}

@media only screen and (max-width: 1320px) {
    .home_down_app {
        width: 250px;
    }

}

/* real css */



@media (max-width: 1024px) {



    .about_img_here{
        width: 70%;
    }
    .more_faq_cont {
        width: 90%;
    }
    .about_img_width {
        display: none;
    }
 
    .member_img_set {
        width: 230px;
    }
    .social_home_page img {
        width: 79px;
    }
    .home_down_app {
        width: 185px;
    }
    .social_home_page {
        margin-bottom: 21px;
    }
    .footer_link_cortoon{
        margin-top: 70px;
    }
    .set_footer {
        width: 100%;
        display: flex;
        justify-content: space-around;
        color: white;
        padding: 45px 45px 20px 45px;
    }
 
}

@media (max-width: 834px) {
    .ab_sect_info {
        width: 676px;
        height: 373px;
        padding: 26px 0px;
    }
    .home_down_app {
        display: none;
    }
    .partner_hall{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .set_footer {
        width: 100%;
        display: flex;
        justify-content: space-around;
       
       
        flex-wrap: nowrap;
    }
    
}

@media (max-width: 768px) {
    .about_contant_here {
        font-size: 18px;
        padding: 5px 20px;
        margin: 0px;
    }
    .ab_sect_info {
        width: 664px;
        height: 373px;
        padding: 26px 0px;
        margin-left: 0px;
    }
    
}

@media (max-width: 480px) {
    .partner_hall_inner {
        overflow: hidden;
        width: 50%;
    }
    .surya_style {
        /* margin-top: -10px; */
        width: 168px;
        font-size: 12px;
        font-weight: revert;
        line-height: 15px;
    }
    .about_set_center_by_media{
        display: flex;
        justify-content: center;
        margin-top: -84px;
    }
    .member_img_set {
        width: 100%;
        margin-bottom: 104px;
    }
    .member_name_contant {
        margin-top: -195px;
        margin-bottom: 40px;
    }
  
    .set_footer {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    .social_home_page img {
        width: 45px;
    }
    .partner_hall {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-top: -140px;
        justify-content: center;
        align-items: center;
    }
    .member_img_set {
        width: 100%;
    }
    .home_member_set_cont {
        display: none;
     
    }
    .about_contant_here {
        font-size: 15px;
        padding: 5px 20px;
        margin: 0px;
    }
    .ab_sect_info {
        width: 90%;
        height: 450px;
        padding: 10px 0px;
        margin-left: 0px;
    }
    .home_video{
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
    .social_home_page {
        margin-bottom: 21px;
        display: none;
    }
}









