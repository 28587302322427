.notify_cont{
    position: fixed;
    z-index: 22343;
    right: 0;
    top: 120px;
    animation: notfiyLineAll 5s ease-in-out;
    opacity: 0;
}
.notify_cont_innner{
    font-size: 20px;
    width: 340px;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    color: white;
    padding: 22px;
    border-left: 7px solid #4b206d;
    animation: notfiyLineWidth 5s linear;
    position: relative;
    /* animation-delay: 4s; */
}
.notify_donw_line{
    width: 100%;
    height: 7px;
    background: #4b206d;
    margin-top: -6px;
    animation: notfiyLine 3s linear;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes notfiyLine {
    0%{
        width: 100%;
        opacity: 1;
    }90%{
        width: 0%;
        opacity: 1;
    }100%{
        width: 0%;
        opacity: 0;
    }
}

@keyframes notfiyLineWidth {
    0%{
        left: 340px;
    }10%{
        left: 0px;
    }90%{
        left: 0px;
    }100%{
        left: 340px;
    }
}
@keyframes notfiyLineAll {
    0%{
        opacity: 1;
    }100%{
        opacity: 1;
    }
    
}
