@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@700&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Nunito', sans-serif; */
  font-family: 'Catamaran', sans-serif;
  /* background: #171717; */
  /* color: white; */
  /* background: rgb(0, 0, 0); */
  background-image: url('./assets/Bg\ 2.png');
}

::selection {
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 0);
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: blueviolet;
}

textarea:focus,
input:focus {
  outline: none;
}

.active {
  cursor: pointer;
  opacity: 1;
}


.spinner_data {
  width: 8vmax;
  height: 8vmax;
  border-right: 4px solid #ffffff;
  border-radius: 100%;
  animation: spinRight 800ms linear infinite;

  &:before,
  &:after {
    content: '';
    width: 6vmax;
    height: 6vmax;
    display: block;
    position: absolute;
    top: calc(50% - 3vmax);
    left: calc(50% - 3vmax);
    border-left: 3px solid #ffffff;
    border-radius: 100%;
    animation: spinLeft 800ms linear infinite;
  }

  &:after {
    width: 4vmax;
    height: 4vmax;
    top: calc(50% - 2vmax);
    left: calc(50% - 2vmax);
    border: 0;
    border-right: 2px solid #ffffff;
    animation: none;
  }
}

@keyframes spinLeft {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(720deg);
  }
}

@keyframes spinRight {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}


