.ep_cont_top{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: white;
}
.ep_edit_person_icon{
    opacity: 0;
    text-align: center;
    margin-top: -125px;
    margin-bottom: 97px;
    color: black;
    transition: .5s;
}
.ep_person_cont:hover .ep_edit_person_icon {
    opacity: 1;
}
.ep_banner_pic{
    width: 100%;
    height: 100vh;
    background: #000000b4;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.ep_close{
    position: fixed;
    color: white;
    top: 20%;
    font-size: 42px;
    right: 20%;
    cursor: pointer;
}
.ep_pop_pic{
    display: flex;
    align-items: center;
    justify-content: center;
}
.ep_pop_pic_submit_btn{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.ep_pic_set_back{
    background: white;
    border-radius: 50%;
    height: 200px;
    display: flex;
    width: 200px;
    align-content: center;
    align-items: center;
    justify-content: center;

}
.ep_submit_pic{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.c_b_c_g{
margin: 10px;
transition: .5s;
}
.c_b_c_g:hover{
    box-shadow: 0px 0px 10px 1px purple;
}
.background_gamer_options{
    position: absolute;
    padding: 21px 32px;
    width: 261px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    /* height: 600px; */
    flex-wrap: wrap;
}
.hoverColorChangeVoilet:hover{
    transition: .5s;
    color: #4b206d;
}