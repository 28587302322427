.gamer_card_save{
    direction: rtl;
    margin-bottom: -61px;
    position: relative;
    padding: 7px;
}
.gamer_card_save img{
    width: 30px;
}
.gamer_card_contant{
    margin-top: -452px;
    text-align: center;
    color: white;
    height: 444px;
    background: #0000006b;
    position: relative;
}
.gamer_card_contant .gc_contant{
    font-size: 17px;
    font-weight: bolder;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px;
}
.gamer_card_upevent{
    border-top: 1px solid gold;
    width: 173px;
    margin: 0;
    padding: 8px;
    font-size: 20px;
}