.chat_icon{
    position: fixed;
    bottom: 25px;
    background: #323232;
    padding: 13px;
    display: flex;
    border-radius: 16px;
    cursor: pointer;
    right: 30px;
    z-index: 22;
    user-select: none;
}

.chat_icon span{
    transition: .5s;
    color: #ffffff;
    font-size: 39px;
}
.chat_icon span:hover{
    transition: .5s;
    color: #41185f;
    font-size: 39px;
}
.chat_massage_container{
    transition: 500ms;
    position: fixed;
    bottom: 15px;
    right: 20px;
    z-index: 9;
    background: white;
    border-radius: 6px;
    background: #000000;
    color: white;
}

.chat_massage_container .chat_cont{
    padding: 15px;
    font-size: 20px;
    /* margin: 10px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 335px;
    height: 60px;
    background: linear-gradient(180deg, #41185f, transparent);
    border-radius: 10px;
    cursor: pointer;
}

.cm_head_show{
    display: flex;
    align-items: center;
    padding: 13px;
    background: linear-gradient(179deg, #4C1C6F, transparent);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 70px;
    justify-content: space-between;
}
.cm_search{
    display: flex;
    justify-content: center;
}
.cm_search input{
    width: 95%;
    display: flex;
    justify-content: center;
    background: #424242;
    border-radius: 30px;
    color: white;
    padding: 5px;
    font-size: 20px;
    border: none;
}
.cm_inner_chat-user{
    width: 400px;
    background: #555555;
    border: none;
    padding: 5px;
    border-radius: 5px;
    font-size: 20px;
    color: white;
}
.cm_data_cont{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.cm_data_cont_ot{
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cm_data_cont_ot img{
    border-radius: 50%;
}
.cm_data_cont_ot span{
    font-size: 11px;
}
.cm_data_cont2{
    display: flex;
    align-items: center;
    padding: 5px;
}
.cm_data_cont_ot2{
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cm_data_cont_ot2 img{
    border-radius: 50%;
}
.cm_data_cont_ot2 span{
    font-size: 11px;
}