.userbio_pen_logo{
    position:absolute;
    cursor: pointer;
    opacity: 0;
}

.sp_bio_inner:hover .userbio_pen_logo{
    transition: 300ms;
    opacity: 1;
    cursor: pointer;
}
.close_bio_sp{
    position: absolute;
    top: 10%;
    right: 10%;
    cursor: pointer;
}
.sp_bio_inner{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.close_real_style{
    position: fixed;
    top: 20%;
    right: 20%;
}

@media (max-width:480px) {
    .close_real_style{
        position: fixed;
        top: 20px;
        right: 20px;
    }
}